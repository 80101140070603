import React from 'react';

import MakeBet from '../../components/Sections/Games/Jackpot/MakeBet';

export default function Termos() {
  return (
    <div className="main-wrapper">
      <MakeBet />
    </div>
  );
}
